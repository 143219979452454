<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>
      <h3 v-if="add_new" style="position: relative;bottom: 9px;">Add Tables</h3>
      <h3 v-if="add_update" style="position: relative;bottom: 9px;">Edit Tables</h3>
      <!-- Avatar Row -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col sm:flex-row profile_camera_icon">
          <div class="new_file_upld">
            <img :src="formData['image_url']||'/400x400.png'"   class="rounded h-32 w-32" />
            <!-- <vs-avatar :src="data.avatar" size="80px" class="mr-4" /> -->
             <div class="blt_buttons" v-if="add_Avatar">
              <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">{{ formData['displayName']  }}</p>
              <input type="file" class="hidden" ref="update_avatar_input" name='image_url' @change="update_avatar" accept=".png,.jpg,.jpeg">
              <vs-button class="" v-if="add_Avatar" @click="$refs.update_avatar_input.click()"><feather-icon icon="CameraIcon" class="inline-block" svgClasses="w-4 h-4" /></vs-button>
            </div>

            <div class="blt_buttons"  v-if="remove_Avatars">
              <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">{{ formData['displayName']  }}</p>
              <input type="file" class="hidden" ref="update_avatar_input" name='image_url' @change="remove_avatar" accept=".png,.jpg,.jpeg">
              <vs-button class=""  color="danger" v-if="remove_Avatars" @click="$refs.update_avatar_input.click()"><feather-icon icon="CameraIcon" class="inline-block" svgClasses="w-4 h-4" /></vs-button>
            </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Content Row -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
           <div class="mt-4">
              <vs-input class="w-full mt-4 required" label="Name" :maxlength=40 placeholder="Name" v-model="formData['name']" v-validate="'required'" name="name" />
              <span class="text-danger text-sm"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
          </div>
          <div class="mt-4">
            <vs-input class="w-full mt-4 required" label="User Limit" placeholder="User Limit" v-model="formData['user_limit']" v-validate="'required'" name="user_limit" @keypress="isNumber($event)" />
            <span class="text-danger text-sm"  v-show="errors.has('user_limit')">{{ errors.first('user_limit') }}</span>
          </div>
          <div class="mt-4">
            <vs-input class="w-full mt-4 required" label="Exit Time" placeholder="Exit Time" v-model="formData['exit_time']" v-validate="'required'" @keypress="isNumber($event)" name="exit_time" />
            <span class="text-danger text-sm"  v-show="errors.has('exit_time')">{{ errors.first('exit_time') }}</span>
          </div>
        </div>
    
        <div class="vx-col md:w-1/2 w-full" id="demo">

           <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">From Date  <span class="text-danger"> * </span></label>
            <flat-pickr v-model="from_date_model" placeholder="From Date" :config="{enableTime: true, dateFormat: 'Y-m-d H:i',minDate: 'today' }"    class="w-full" name="from_date" />
            <span class="text-danger text-sm"  v-show="errors.has('from_date')">{{ errors.first('from_date') }}</span>
          </div>
          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">To Date  <span class="text-danger"> * </span></label>
            <flat-pickr v-model="to_date_model" placeholder="To Date"  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' ,minDate: 'today'}"   class="w-full" name="to_date" />
            <span class="text-danger text-sm"  v-show="errors.has('to_date')">{{ errors.first('from_date') }}</span>
            <span class="text-danger text-sm" v-if="errEndTime">To Date Must Always Greater Than From Date....!!!</span>

          </div>
         
          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Status</label>
            <vs-switch color="success" v-model="status_model">
                <span slot="on">Active</span>
                <span slot="off">InActive</span>
            </vs-switch>
          </div>
        </div>
      </div>
      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8  flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm">Save Changes</vs-button>
            <vs-button class="ml-4 mt-2" v-if="reset_btn"  color="warning" @click="reset_data">Reset</vs-button>
            <vs-button class="ml-4 mt-2" v-if="canel_btn" type="border" color="warning" @click="cancel_data">Cancel</vs-button>
          </div>
        </div>
      </div>

    </vx-card>
</template>

<script>
// import moduleChatroom          from '@/store/chatroom/moduleChatroom.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  data () {
    return {
      promptName    :'Edit Profile',
      formData      :{},
      interests     :[],
       add_Avatar: true,
       remove_Avatars : '',
       upload_image:0,
         add_new : true,
       add_update :false,
        reset_btn : true,
       canel_btn : false,
       errEndTime:false
    }
  },
  components: {
    vSelect,
    flatPickr
  },
  computed: {
    chatroom ()     { 
      if(!this.$route.params.id){
        return {'org_id':this.$store.state.AppActiveUser.org_id,"country_id":this.$store.state.AppActiveUser.country_id,'status':false,'image_url':'/400x400.png','chat_history_url':'/400x400.png'};
      }else{
         if(this.formData.image_url != ""){
             this.remove_Avatars = true;
             this.add_Avatar = false;
             this.upload_image = 1
             this.add_new = false
            this.add_update = true
              this.reset_btn = false
            this.canel_btn = true
        }
        return this.$store.getters['chatroom/getChatroom'](this.$route.params.id)
      }
     }, 
    status_model: {
      get () {
        return this.formData['status']=='1'?true:false
      },
      set (status) {
        this.formData['status'] = status?'1':'0'
      }
    },
    from_date_model: {
      get () {
        
        return this.formData['from_date']
      },
      set (date) {
        const d = new Date(this.formData['from_date'])
          var from_date_format = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate() 
          const to_date = new Date(this.formData['to_date'])
          var to_date_format = to_date.getFullYear() + '-' + (to_date.getMonth()+1) + '-' + to_date.getDate() 

        var start = new Date(from_date_format)
        var end =new Date(to_date_format)
        var diff = new Date(end - start);
        var days = diff/1000/60/60/24;
      if(days < 0){
       // if(from_date_format > to_date_format){
          this.errEndTime = true
        }else{
          this.errEndTime = false
        }
        this.formData['from_date'] = date
      }
    },
    to_date_model: {
      get () {
        return this.formData['to_date']
      },
      set (date) {
        var toDate = new Date(Date.parse(date));
        var fromDate = new Date(Date.parse(this.formData['from_date']));
         var startDay = new Date(toDate);
        var endDay = new Date(fromDate);
        var millisecondsPerDay = 1000 * 60 * 60 * 24;

        var millisBetween = startDay.getTime() - endDay.getTime();
        var days = millisBetween / millisecondsPerDay;
        var to_dd = toDate.getDate();
        var to_mm = toDate.getMonth();
        var to_y = toDate.getFullYear();

        var to_h = toDate.getHours();
        var to_m = toDate.getMinutes();
        var to_s = toDate.getSeconds();

        var from_dd = fromDate.getDate();
        var from_mm = fromDate.getMonth();
        var from_y = fromDate.getFullYear();

         var from_h = fromDate.getHours();
        var from_m = fromDate.getMinutes();
        var from_s = fromDate.getSeconds();
        var toFormattedDate = to_mm + '-' + to_dd + '-' + to_y;
        var fromFormattedDate = from_mm + '-' + from_dd + '-' + from_y;

        //if(new Date(toFormattedDate) < new Date(fromFormattedDate))
        if( Math.floor(days) < -1)
        { 
           this.errEndTime = 1
        }else if((from_h > to_h) || (to_m < from_m ) ){
          this.errEndTime = 1
        }else{
          this.errEndTime = 0
        }
        this.formData['to_date'] = date
      }
    },
    validateForm () {
       return !this.errors.any() && this.errEndTime == 0 && this.formData.name !== '' && this.formData.name && this.formData.user_limit !== '' && this.formData.user_limit && this.formData.exit_time !== '' && this.formData.exit_time && this.upload_image == 1
      //return true
    }
  },
  methods: {
     cancel_data(){
        this.$router.push('/chatroom/all/').catch(() => {})
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode == 46 || (charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    save_changes () { 
     // if (!this.validateForm) return
       if(this.formData['from_date'] == undefined){
       this.formData['from_date'] =new Date()
      }
      if(this.formData['to_date'] == undefined){
       this.formData['to_date'] =new Date(new Date().getTime()+(1*24*60*60*1000));
      }
     
       this.$validator.validateAll().then(result => {
       if (result) {
         this.$vs.loading()
      if(!this.$route.params.id){
        this.$store.dispatch('chatroom/addChatroom', Object.assign({}, this.formData)).then((res) => {
          this.$store.dispatch('chatroom/fetchChatrooms')
          this.$vs.loading.close()  
              const success = 'Chatroom added successfully...!!!'
              this.notif_sucess(success) 
              }).catch((error) => { 
                  if( error.message == "Request failed with status code 423"){
                          error.message = 'Chatroom is created by same name..!!!'
                      }else{
                          error = error.message
                      }
                  this.$vs.loading.close()
                  this.notif(error)     
                })
      }else{
        this.$store.dispatch('chatroom/updateChatroom', Object.assign({}, this.formData)).then((res) => {
          this.$store.dispatch('chatroom/fetchChatrooms')
          this.$vs.loading.close()  
              const success = 'Chatroom Updated Successfully...!!!'
              this.notif_sucess(success) 
              }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
                })
          }
       }
      })
    },
    notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
     notif_sucess (success) {
      this.$vs.notify({
        title: 'success',
        text: success,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"success"
      })
    },
    reset_data () {
      this.formData = Object.assign({}, this.chatroom)
      delete this.formData['name']
      delete this.formData['from_date']
      delete this.formData['to_date']
    },
     notif_failed (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"danger"
      })
    },
     update_avatar (e) {
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                      if((img.height !== 400 || img.width !== 400) || (sizeInMB < 2)){
                            this.errImageupload = 0
                      }else{
                            this.errImageupload = 1
                            this.$vs.loading.close() 
                            const error = "File size more 2 MB OR Resolution greater than 400*400 can not been upload.Try with other image less than 2 MB OR Resolution less than or equal to 400*400.....!!!!"
                            this.notif_failed(error)
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
     this.$vs.loading()
      this.$store.dispatch('chatroom/uploadImage', {image:e.target.files[0], room_id:this.$route.params.id}).then((res)=>{
        this.formData['image_url'] = res
        this.remove_Avatars = true;
        this.add_Avatar = false;
         this.$vs.loading.close()
         this.upload_image = 1
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
                })
      }
    }, 200);
  }, notif_failed (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"danger"
      })
    },
     update_avatar (e) {
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                      if((img.height !== 400 || img.width !== 400) || (sizeInMB > 2)){
                            this.$refs.update_avatar_input.value = ''
                            this.errImageupload = 1
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 400*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
     this.$vs.loading()
      this.$store.dispatch('chatroom/uploadImage', {image:e.target.files[0], room_id:this.$route.params.id}).then((res)=>{
         this.formData['image_url'] = res
         this.$refs.update_avatar_input.value = ''
         this.remove_Avatars = true;
        this.add_Avatar = false;
        this.upload_image = 1
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
                })
      }
    }, 200);
  },
    remove_avatar(e) {
        let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                      if((img.height !== 400 || img.width !== 400) || (sizeInMB > 2)){
                            this.$refs.update_avatar_input.value = ''
                            this.errImageupload = 1
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 400*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
       this.$vs.loading()
      this.$store.dispatch('chatroom/removeImage', {image:e.target.files[0], room_id:this.$route.params.id,oldImage:this.formData['image_url']}).then((res)=>{
         this.formData['image_url'] = res
         this.$refs.update_avatar_input.value = ''
         this.remove_Avatars = true;
        this.add_Avatar = false;
        this.upload_image = 1
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
         }
    }, 200);
    }
  },
  created () {
    // this.$store.registerModule('chatroom', moduleChatroom)
    if(!this.chatroom){
      this.$vs.loading()
      this.$store.dispatch('chatroom/fetchChatrooms').then((res)=>{
        this.formData = Object.assign({}, this.chatroom)
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
    }else{
      this.formData = Object.assign({}, this.chatroom)
    }
    //this.formData['from_date'] =new Date()
      // this.formData['to_date'] =new Date(new Date().getTime()+(1*24*60*60*1000));      
  },
  beforeDestroy() {
    // this.$store.unregisterModule('chatroom')
  }
}
</script>
